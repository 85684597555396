
import DiningService from '@/services/dining-selector';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  setup() {
    const service = new DiningService();

    const options = ref<string[]>([]);
    const getOptions = () => {
      options.value = service.options;
    };

    getOptions();

    const selectedOption = ref<string>('');
    const optionToAdd = ref<string>('');
    const error = ref<string>('');
    const resetMessages = () => {
      selectedOption.value = '';
      error.value = '';
    };

    const updateSelectedOption = (value: string) => {
      resetMessages();
      selectedOption.value = value;
    };

    const addOption = (e: Event) => {
      e.preventDefault();
      resetMessages();
      error.value = service.addOption(optionToAdd.value) || '';

      optionToAdd.value = '';
      getOptions();
    };
    const deleteOption = (option: string) => {
      optionToAdd.value = '';
      resetMessages();
      error.value = service.deleteOption(option) || '';
      getOptions();
    };
    const resetOptions = () => {
      optionToAdd.value = '';
      resetMessages();
      service.resetOptions();
      getOptions();
    };
    const selectOption = () => {
      optionToAdd.value = '';
      resetMessages();
      updateSelectedOption(service.selectOption());
    };

    return {
      service,
      error,
      options,
      getOptions,
      selectedOption,
      updateSelectedOption,
      optionToAdd,
      addOption,
      deleteOption,
      resetOptions,
      selectOption,
    };
  },
});
