
// import Versions from "@/components/Versions.vue";
import { WEBSITE_URLS } from '@/constants';
import { defineComponent } from 'vue';

export default defineComponent({
  // components: {
  //   Versions,
  // },
  setup() {
    return { pages: WEBSITE_URLS };
  },
});
