
import { IProject } from '@/models/project';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    project: {
      type: Object as () => IProject,
      required: true,
    },
    localProject: {
      type: Boolean,
      default: false,
    },
  },
});
