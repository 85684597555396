
import Loader from '@/components/Loader.vue';
import ResumeData from '@/components/ResumeData.vue';
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    Loader,
    ResumeData,
  },
  setup() {
    const store = useStore();
    store.dispatch('getResume');

    return {
      resume: computed(() => store.state.resume),
    };
  },
});
